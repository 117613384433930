<template>
  <div class="container">
    <field
      label="系统基本佣金："
      :asterisk="true"
      labelWidth="130px"
      :validation="validation.brokerageFirst"
    >
      <div class="money">
        <el-input
          v-model="formData.brokerageFirst"
          placeholder="请输入基本佣金金额"
        ></el-input>
        <el-input
          v-model="formData.brokerageSecond"
          placeholder="请输入基本佣金金额"
        ></el-input>
        <el-input
          v-model="formData.brokerageThird"
          placeholder="请输入基本佣金金额"
        ></el-input>
      </div>
    </field>
    <field
      label="提现时间："
      :asterisk="true"
      labelWidth="130px"
      :validation="validation.cashOutDayList"
    >
      <div class="money">
        <div>
          <div style="line-height: 40px;">
            <el-radio-group
              v-model="formData.cashOutType"
              @change="formData.cashOutDayList = []"
            >
              <el-radio :label="1">按每月</el-radio>
              <el-radio :label="2">按每周</el-radio>
            </el-radio-group>
          </div>
          <el-select
            v-model="formData.cashOutDayList"
            placeholder="请选择角色权限"
            style="width: 100%"
            multiple
          >
            <el-option
              v-for="item in selectOptions"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </field>
    <field
      label="最小提现额度："
      :asterisk="true"
      labelWidth="130px"
      :validation="validation.cashOutLimit"
    >
      <div class="money">
        <el-input v-model="formData.cashOutLimit" placeholder="最小提现额度">
          <template slot="append">元</template>
        </el-input>
        <span style="color: #999; font-size: 12px;line-height: 32px;">（最小1元，最大5000元）</span>
      </div>
    </field>

    <field
      label="付款等待时间："
      :asterisk="true"
      labelWidth="130px"
      :validation="validation.orderPayTime"
    >
      <div class="money">
        <el-input placeholder="请输入内容" v-model="formData.orderPayTime">
          <template slot="append">分钟</template>
        </el-input>
        <span style="color: #999; font-size: 12px;line-height: 32px;">（最小1分钟，最大59分钟）</span>
      </div>
    </field>

    <field label="客户身份：" :asterisk="true" labelWidth="130px">
      <div>
        <div
          class="customer"
          v-for="item in formData.customerIdentityList"
          :key="item.id"
        >
          <el-input v-model="item.name" placeholder="请输入客户信息"></el-input>
          <el-input
            v-model="item.remark"
            placeholder="请输入客户描述"
          ></el-input>
        </div>
        <el-button @click="submit" type="primary">保存</el-button>
      </div>
    </field>
  </div>
</template>

<script>
import * as v from '@/utils/validator'
import * as R from 'ramda'
import validation from '@/mixins/validation'
import * as configService from '@/api/basicConfig'

const monthOptions = R.range(1, 32).map(item => ({
  label: `${item}号`,
  value: item
}))

const weekOptions = [
  { label: '星期一', value: 1 },
  { label: '星期二', value: 2 },
  { label: '星期三', value: 3 },
  { label: '星期四', value: 4 },
  { label: '星期五', value: 5 },
  { label: '星期六', value: 6 },
  { label: '星期七', value: 7 }
]

export default {
  mixins: [
    validation({
      rules: {
        cashOutDayList: [v.required('请选择提现时间')],
        cashOutLimit: [v.required('请输入提现额度')],
        customerIdentityList: [v.required()],
        orderPayTime: [v.required('请输入付款等待时间'), v.isInteger()]
      },
      field: 'formData'
    })
  ],
  created () {
    this.fetchData()
  },
  data () {
    return {
      formData: {
        id: 1,
        brokerageFirst: '',
        brokerageSecond: '',
        brokerageThird: '',
        cashOutType: 1,
        cashOutDayList: [],
        cashOutLimit: '',
        orderPayTime: '10',
        customerIdentityList: [
          { name: '金牌合伙人', remark: '负责商品添加发布下架等' },
          { name: '金牌合伙人', remark: '负责商品添加发布下架等' },
          { name: '金牌合伙人', remark: '负责商品添加发布下架等' }
        ]
      }
    }
  },
  computed: {
    selectOptions () {
      console.log('monthOptions', monthOptions)
      return this.formData.cashOutType === 1 ? monthOptions : weekOptions
    }
  },
  methods: {
    async fetchData () {
      try {
        const res = await configService.detail()
        this.formData = {
          id: res.id,
          brokerageFirst: this._pennyToYuan(res.brokerageFirst),
          brokerageSecond: this._pennyToYuan(res.brokerageSecond),
          brokerageThird: this._pennyToYuan(res.brokerageThird),
          cashOutType: res.cashOutType,
          cashOutDayList: res.cashOutDayList,
          cashOutLimit: this._pennyToYuan(res.cashOutLimit),
          orderPayTime: res.orderPayTime.toString(),
          customerIdentityList: res.customerIdentityList
        }
      } catch (err) {
        // handle err
      }
    },
    deleteCustom (index) {
      const data = R.clone(this.formData)
      data.customerIdentityList = data.customerIdentityList.filter(
        (_, i) => i !== index
      )
      this.formData = data
    },
    async submit () {
      try {
        await this.$validate()
        const postData = R.clone(this.formData)
        postData.brokerageFirst = this._yuanToPenny(postData.brokerageFirst)
        postData.brokerageSecond = this._yuanToPenny(postData.brokerageSecond)
        postData.brokerageThird = this._yuanToPenny(postData.brokerageThird)
        postData.cashOutLimit = this._yuanToPenny(postData.cashOutLimit)
        postData.orderPayTime = parseInt(this.formData.orderPayTime)
        postData.cartInvalidTime = 15
        postData.orderCompleteTime = 7
        await configService.update(postData)
        this.$notify.success({
          title: '更新配置成功'
        })
      } catch (err) {
        // this.$notify.error({
        //   title: '更新配置失败'
        // })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  text-align: left;
  padding: 40px;
  background: #ffffff;
}

.money {
  display: grid;
  grid-template-columns: repeat(3, 295px);
  grid-column-gap: 20px;
}

.customer {
  display: grid;
  grid-template-columns: 1fr 2.5fr 100px;
  grid-gap: 20px;
  margin-bottom: 10px;
}
</style>
